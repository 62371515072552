<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-input v-model:value="inputs.name" placeholder="药名" /> -->
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
        <!-- <a-button style="margin-left: 10px" type="primary" @click="visible=true">
          <template #icon>
            <PlusOutlined />
          </template>
          批量上传
        </a-button> -->
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="{ pageSize ,total,current,showTotal: (total) => `共 ${total} 条`,showSizeChanger:true,pageSizeOptions: ['10', '15', '20', '30', '50']}" :scroll="{ y: 500 }" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations" v-if="record.status!='已取消'">
              <a @click="getTask(record)">查看</a>
            </div>
          </div>
        </template>
        <template v-for="col in ['num']" #[col]="{ text, record,index }" :key="col">
          <div>{{index+1}}</div>
        </template>
        <template v-for="col in ['infos']" #[col]="{ text, record }" :key="col">
          <span style="margin-right: 15px;" v-for="item in text">{{item.displayName}}：{{item.displayValue}}</span>
        </template>
      </a-table>

    </div>
    <!-- 详情 -->
    <getTask v-model="visible" :record="selectedRow" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}
:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search } from "/src/api/task.js";
import getTask from '/src/components/task/getTask.vue';

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    CheckOutlined,
    CloseOutlined,
    getTask,
  },
  name: "药品管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      type: [],
      vendor: [],
      visible2: false,
      active: undefined,
      id: undefined,
      inputs: {
        name: undefined,
        category: "",
        vendorId: "",
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "序号",
          key: "num",
          dataIndex: "num",
          width: "5%",
          slots: {
            customRender: "num"
          }
        },
        {
          title: "日期",
          key: "createdDate",
          dataIndex: "createdDate",
          width: "15%",
          slots: {
            customRender: "createdDate"
          }
        },
        {
          title: "创建人",
          key: "creatorName",
          dataIndex: "creatorName",
          ellipsis: true,
          width: "10%",
          slots: {
            customRender: "creatorName"
          },
        },
        {
          title: "类型",
          width: "10%",
          key: "category",
          dataIndex: "category",
          slots: {
            customRender: "category"
          }
        },
        {
          title: "状态",
          width: "10%",
          key: "status",
          dataIndex: "status",
          slots: {
            customRender: "status"
          }
        },
        {
          title: "附加信息",
          width: "40%",
          key: "infos",
          dataIndex: "infos",
          slots: {
            customRender: "infos"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      total: 0,
      current: 1,
      pageSize: 10,
      loading: true,
      editableData: reactive({}),
      selectedRow: "",
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {

  },
  methods: {
    resetForm() {
    },
    getTask(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.current = pagination.current
      this.pageSize = pagination.pageSize
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        category: this.inputs.category,
        pageSize: this.$store.state.tableSize
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.data = res.data;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },



  }
};
</script>